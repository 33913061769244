import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
// import ThreeView from '../views/pages/ThreeView.vue'
import LoginView from "@/views/pages/LoginView.vue";
import WebView from "@/views/pages/WebView.vue";
import MobView from "@/views/pages/MobView.vue";
import CateView from "@/views/pages/CateView.vue";
import ContentView from "@/views/pages/ContentView.vue";
import getContentView from "@/views/pages/getContentView.vue";
import ProductView from "@/views/pages/ProductView.vue";
import getProductView from "@/views/pages/getProductView.vue";
import BannerView from "@/views/pages/BannerView.vue";
import getBannerView from "@/views/pages/getBannerView.vue";
import getLinkView from "@/views/pages/getLinkView.vue";
import LinkView from "@/views/pages/LinkView.vue";
import waterView from "@/views/pages/WaterView.vue";
import LabelView from "@/views/pages/LabelView.vue";
import getLabelView from "@/views/pages/getLabelView.vue";
import MessageView from "@/views/pages/MessageView.vue";
import UserView from "@/views/pages/UserView.vue";
import AccessView from "@/views/pages/AccessView.vue";
import LangView from "@/views/pages/LangView.vue";
import HistoryView from "@/views/pages/HistoryView.vue";
import getHistoryView from "@/views/pages/getHistoryView.vue";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta:{
      requireLogin:true
    },
    children: [
      {
        path: '/history',
        name: 'history',
        component: HistoryView,
        meta:{
          requireLogin:true
        }
      },
      {
        path: '/gethistory',
        name: 'gethistory',
        component: getHistoryView,
        meta:{
          requireLogin:true
        }
      },
      {
        path: '/webbase',
        name: 'webbase',
        component: WebView,
        meta:{
          requireLogin:true
        }
      },
      {
        path: '/mobbase',
        name: 'mobbase',
        component: MobView,
        meta:{
          requireLogin:true
        }
      },
      {
        path: '/category',
        name: 'category',
        component: CateView,
        meta:{
          requireLogin:true
        }
      },
      {
        path: '/content',
        name: 'content',
        component: ContentView,
        meta:{
          requireLogin:true
        }
      },
      {
        path: '/getcontent',
        name: 'getcontent',
        component: getContentView,
        meta:{
          requireLogin:true
        }
      },
      {
        path: '/product',
        name: 'product',
        component: ProductView,
        meta:{
          requireLogin:true
        }
      },
      {
        path: '/getproduct',
        name: 'getproduct',
        component: getProductView,
        meta: {
          requireLogin: true
        }
      },
      {
        path: '/banner',
        name: 'banner',
        component: BannerView,
        meta:{
          requireLogin:true
        }
      },
      {
        path: '/getbanner',
        name: 'getbanner',
        component: getBannerView,
        meta:{
          requireLogin:true
        }
      },
      {
        path: '/link',
        name: 'link',
        component: LinkView,
        meta:{
          requireLogin:true
        }
      },{
        path: '/lang',
        name: 'lang',
        component: LangView,
        meta:{
          requireLogin:true
        }
      },
      {
        path: '/getlink',
        name: 'getlink',
        component: getLinkView,
        meta:{
          requireLogin:true
        }
      },
      {
        path: '/water',
        name: 'water',
        component: waterView,
        meta:{
          requireLogin:true
        }
      },{
        path: '/label',
        name: 'label',
        component: LabelView,
        meta:{
          requireLogin:true
        }
      },{
        path: '/getlabel',
        name: 'getlabel',
        component: getLabelView,
        meta:{
          requireLogin:true
        }
      },{
        path: '/message',
        name: 'message',
        component: MessageView,
        meta:{
          requireLogin:true
        }
      },{
        path: '/user',
        name: 'user',
        component: UserView,
        meta:{
          requireLogin:true
        }
      },{
        path: '/access',
        name: 'access',
        component: AccessView,
        meta:{
          requireLogin:true
        }
      }
    ]
  },
  {
    path: '/login',
    name:'login',
    component: LoginView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

export default router
