<template>
<el-form ref="form" :model="form" label-width="80px">
  <el-form-item label="基础字段">
    <el-input type="textarea" :rows="30"  v-model="form.field" placeholder=""></el-input>
  </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit">保存</el-button>
<!--      <el-button>取消</el-button>-->
    </el-form-item>
  </el-form>
</template>
<script>
import axios from "axios";

export default {
  name: "WebView",
  data() {
    return {
      form: {
        lang: "",
      }
    };
  },
  created() {
    this.getBase();
  },
  methods: {
    getBase(){
      axios.get('/api/Base/getlangfield.html').then((res)=>{
        if(res.data.code == 200){
          this.form = res.data.data;
        }
      });
    },
    onSubmit() {
        this.axios({
          method:'post',
          url:'/api/Base/setlangfield.html',
          data:this.form
        }).then((res)=>{
          if(res.data.code != 200) {
            alert(res.data.msg);
          }
          else{
            alert(res.data.msg);
          }
        })
    },
  },
};
</script>
<style scoped lang='scss'>
.el-input-number {
  margin-left: 10px;
}
</style>