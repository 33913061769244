<template>
  <el-dialog
      title="栏目编辑"
      :visible.sync="dialogVisible"
      @open="getCategory"
      @close="handleClose"
      >
    <el-form ref="form" :model="form" label-width="100px">
      <input type="hidden" v-model="form.id" />
      <el-form-item label="栏目标题">
        <el-input v-model="form.cate_name"></el-input>
      </el-form-item>
      <el-form-item label="上级栏目">
        <el-select v-model="form.pid"  placeholder="请选择">
          <el-option
              v-for="item in cateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="栏目别名">
        <el-input v-model="form.cate_title"></el-input>
      </el-form-item>
      <el-form-item label="模板名称">
        <el-input v-model="form.cate_template" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="排序">
        <el-input-number v-model="form.top" :min="0" :max="100" label=""></el-input-number>
      </el-form-item>
      <el-form-item label="导航栏显示">
        <el-select v-model="form.isshow"  placeholder="请选择">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="栏目简介">
        <el-input type="textarea" :rows="2"  v-model="form.cate_info" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="矢量图">
        <el-input type="textarea" :rows="2"  v-model="form.cate_svg" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="关联产品">
        <el-input v-model="form.cate_pro"></el-input>
      </el-form-item>
      <el-form-item label="关联案例">
        <el-input v-model="form.cate_case"></el-input>
      </el-form-item>
      <el-form-item label="栏目链接">
        <el-input v-model="form.islink"></el-input>
      </el-form-item>
      <el-form-item label="seo标题">
        <el-input v-model="form.seo_title"></el-input>
      </el-form-item>
      <el-form-item label="seo关键词">
        <el-input v-model="form.seo_keyword"></el-input>
      </el-form-item>
      <el-form-item label="seo简介">
        <el-input v-model="form.seo_description"></el-input>
      </el-form-item>

      <el-form-item label="修饰名称">
        <el-input v-model="form.cate_subtitle"></el-input>
      </el-form-item>
      <el-form-item label="图片链接">
        <el-upload
            class="upload-demo"
            action="/api/Upload/upload.html"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-success="handleAvatarSuccess"
            :file-list="form.fileList"
            list-type="picture"
            :limit=1
            name="image[]"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">
            只能上传jpg/png文件，且不超过2Mb
          </div>
        </el-upload>
        <el-input v-model="form.cate_img" type="hidden"></el-input>
      </el-form-item>
      <el-form-item label="栏目图片">
        <el-upload
            class="upload-demo"
            action="/api/Upload/upload.html"
            :on-preview="handlePreview2"
            :on-remove="handleRemove2"
            :on-success="handleAvatarSuccess2"
            :file-list="form.fileList2"
            list-type="picture-card"
            :limit=10
            name="image[]"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">
            只能上传jpg/png文件，且不超过2Mb
          </div>
        </el-upload>
        <el-input v-model="form.cate_imgs" type="hidden"></el-input>
      </el-form-item>
      <el-form-item label="正文">
        <div style="border: 1px solid #ccc;">
          <Toolbar
              style="border-bottom: 1px solid #ccc"
              :editor="editor"
              :defaultConfig="toolbarConfig"
              :mode="mode"
          />
          <Editor
              style="height: 500px; overflow-y: hidden;"
              v-model="form.cate_main"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onCreated="onCreated"
          />
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button @click="handleClose">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>

</template>

<script>
import axios from "axios";
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

export default {
  name: "getCateView",
  components: { Editor, Toolbar },
  data(){
    return{
      dialogVisible:this.show,
      fileList:[],
      form: {
        id: "",
        cate_name: "",
        cate_title: "",
        cate_template: "",
        cate_svg: "",
        top: 0,
        isshow: 0,
        pid: '',
        islink: "",
        seo_title: "",
        cate_info: "",
        cate_pro: "",
        cate_case: "",
        seo_keyword: "",
        seo_description: "",
        cate_subtitle: "",
        cate_main: "",
        fileList:[],
        fileList2:[],
        cate_img: "",
        cate_imgs: ""
      },
      options: [{
        value: 0,
        label: '都显示'
      }, {
        value: 1,
        label: '头部导航栏'
      }, {
        value: 2,
        label: '尾部导航栏'
      }, {
        value: 3,
        label: '不显示'
      }],
      cateList: [],
      editor: null,
      toolbarConfig: { },
      editorConfig: {
        placeholder: '请输入内容...',
        MENU_CONF: {
          'uploadImage': {
            // customUpload: this.uploadImg,
            server: '/api/Upload/uploadEditor.html',
            fieldName: 'image[]',
            maxFileSize: 2 * 1024 * 1024, // 2M
            maxNumberOfFiles: 50,
            allowedFileTypes: ['image/*'],
            timeout: 5 * 1000, // 5 秒
          },
          'uploadVideo': {
            // customUpload: this.uploadImg,
            server: '/api/Upload/editorFile.html',
            withCredentials: true,
            fieldName: 'files[]',
            maxFileSize: 200 * 1024 * 1024, // 200M
            // maxNumberOfFiles: 3,
            // allowedFileTypes: ['video/*'],
            // timeout: 15 * 1000, // 5 秒
          }
        }
      },
      mode: 'default', // or 'simple'
    }
  },
  watch:{
    show(val){
      this.dialogVisible = val
      // this.resetData();
    }
  },
  props:{
    show: {
      type: Boolean,
      default: false
    },
    id:{
      type: [String,Number],
      default: '0'
    }
  },
  // created() {
  //   this.getCategory();
  // },
  methods:{
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    getCategory() {
      let id = this.$props.id;
      axios.get('/api/Category/easyList.html?id='+id).then((res)=>{
        this.cateList = res.data;
      });


      if(id > 0){
        axios.get('/api/Category/getCate.html?id='+id).then((res)=>{
          if(res.data.code == 200){
            this.form = res.data.data;
          }
        });
      }
    },
    resetData(){
      this.form = this.$options.data().form;
    },
    handleClose() {
      this.$emit("dialogShowChange",false);
      this.resetData()
    },
    handleAvatarSuccess(res) {
      let array = Object.values(res);
      this.form.cate_img = array[0]['file_url'];
    },
    handleAvatarSuccess2(res) {
      let array = Object.values(res);
      let img = this.form.cate_imgs;
      var imglist = [];
      if(img) imglist = img.split(',');
      imglist.push(array[0]['file_url']);
      this.form.cate_imgs = imglist.join(',');
    },
    handleChange(value) {
      console.log(value);
    },
    handleChange2(value) {
      console.log(value);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handleRemove2(file, fileList) {
      let img = this.form.cate_imgs;
      var imglist = img.split(',');
      var url = this.expurl(file.url);
      console.log(url);
      for(let i = 0; i < imglist.length; i++){
        if(imglist[i] == url){
          imglist.splice(i,1);
        }
      }
      this.form.cate_imgs = imglist.join(',');
      console.log(fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handlePreview2(file) {
      console.log(file);
    },
    expurl(url){
      url = url.split('upload/');
      return url[1];
    },

    onSubmit() {
      axios({
        method:'post',
        url:'/api/Category/setCate.html',
        data:this.form
      }).then((res)=>{
        if(res.data.code != 200) {
          alert(res.data.msg);
        }
        else{
          alert(res.data.msg);
          this.handleClose();
        }
      })
    },
  }
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>

</style>