<template>
  <div>
   <div>
     <p  class="category">栏目管理</p>

   </div>
    <div>
      <el-form :inline="true" :model="form" class="demo-form-inline">
        <el-form-item label="所属栏目">
          <el-select v-model="form.cateid" placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>   <el-button size="" @click="openCate()"  class="category add"  style="margin: 20px auto">新增</el-button>
      </el-form>

    </div>
  <el-table
      id="table"
      :data="tableData"
      style="width: 100%">
    <el-table-column
        label="ID"
        width="180">
      <template slot-scope="scope">
        <span style="" >{{ scope.row.id }}</span>
      </template>
    </el-table-column>
    <el-table-column
        label="栏目名称"
        >
      <template slot-scope="scope">
        <span style="" v-html="scope.row.cate_name"></span>
      </template>
    </el-table-column>
    <el-table-column
        label="栏目修饰名"
        width="180">
      <template slot-scope="scope">
        <span style="">{{ scope.row.cate_subtitle }}</span>
      </template>
    </el-table-column>
    <el-table-column
        label="是否显示"
        width="180">
      <template slot-scope="scope">
        <span style="">{{ scope.row.isshow }}</span>
      </template>
    </el-table-column>
    <el-table-column label="操作" width="180">
      <template slot-scope="scope">
        <el-button size="mini" @click="openCate(scope.row.id)">编辑</el-button>
        <el-button
            size="mini"
            type="danger"
            @click="deleteCate(scope.$index,scope.row)">删除</el-button>
      </template>
    </el-table-column>

  </el-table>
    <Dialog  :id='id' :show.sync="dialogVisible" @dialogShowChange="dialogShowChange"></Dialog>

  </div>
</template>
<script>
import axios from "axios";
import Dialog from "@/views/pages/getCateView.vue"

export default {
  name: "CateView",
  data() {
    return {
      form:{
        cateid:''
      },
      options:[],
      dialogVisible: false, //当前的dialog是不是显示
      tableData: [],
      id: '',
    }
  },
  components:{
    Dialog,
  },
  created() {
    this.getCate();
    this.getCates();

  },
  methods: {
    openCate(id){
      this.id = id
      this.dialogVisible =true
    },
    dialogShowChange(val){
      this.dialogVisible = val
      this.getCate();
    },
    onSubmit() {
      this.getCate()
    },
    getCates(){
      axios.get('/api/Category/getCates.html').then((res)=>{
        if(res.data.code == 200){
          this.options = res.data.data;
        }
      });
    },
    getCate(){
      var cateid = this.form.cateid
      var str = '';
      if(cateid) str = "?cateid="+cateid
      axios.get('/api/Category/index.html'+str).then((res)=>{
        this.tableData = res.data;
      });
    },
    deleteCate(index,row){
      let tdata = this.tableData;
      this.$confirm("是否确认删除","提示",{
        confirmButtonText:'确定',
        cancelButtonText:'取消',
      }).then(function (){
        axios.delete('/api/Category/deleteCate.html',{data:{id:row.id}}).then((res)=>{
          tdata.splice(index, 1);
          alert(res.data.msg)
        });
      }).catch(function (){

      })

    }
  }
};
</script>
<style>
/*.el-table tr{height: 40px;}*/
/*#table.el-table .el-table__cell{padding: 0;}*/
</style>