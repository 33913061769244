<template>
  <el-dialog
      title="历程编辑"
      :visible.sync="dialogVisible"
      @open="getHistory"
      @close="handleClose"
      >
    <el-form ref="form" :model="form" label-width="100px">
      <input type="hidden" v-model="form.id" />
      <el-form-item label="历程年代">
        <el-input v-model="form.h_title"></el-input>
      </el-form-item>
      <el-form-item label="历程简介">
        <el-input type="textarea" :rows="4"  v-model="form.h_info" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="图片链接">
        <el-upload
            class="upload-demo"
            action="/api/Upload/upload.html"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-success="handleAvatarSuccess"
            :file-list="form.fileList"
            list-type="picture"
            :limit=1
            name="image[]"
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">
            只能上传jpg/png文件，且不超过2Mb
          </div>
        </el-upload>
        <el-input v-model="form.h_img" type="hidden"></el-input>
      </el-form-item>
      <el-form-item label="排序">
        <el-input-number v-model="form.top" :min="0" :max="100" label=""></el-input-number>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button @click="handleClose">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>

</template>

<script>
import axios from "axios";

export default {
  name: "getHistoryView",
  data(){
    return{
      dialogVisible:this.show,
      fileList:[],
      form: {
        id: "",
        h_title: "",
        top: 0,
        h_info: "",
        h_img : ""
      },
      mode: 'default', // or 'simple'
    }
  },
  watch:{
    show(val){
      this.dialogVisible = val
      // this.resetData();
    }
  },
  props:{
    show: {
      type: Boolean,
      default: false
    },
    id:{
      type: [String,Number],
      default: '0'
    }
  },
  // created() {
  //   this.getCategory();
  // },
  methods:{
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    getHistory() {
      let id = this.$props.id;
      if(id > 0){
        axios.get('/api/History/getHistory.html?id='+id).then((res)=>{
          if(res.data.code == 200){
            this.form = res.data.data;
          }
        });
      }
    },
    resetData(){
      this.form = this.$options.data().form;
    },
    handleClose() {
      this.$emit("dialogShowChange",false);
      this.resetData()
    },
    handleAvatarSuccess(res) {
      let array = Object.values(res);
      this.form.h_img = array[0]['file_url'];
    },

    handleChange(value) {
      console.log(value);
    },

    handleRemove(file, fileList) {
      this.form.h_img = '';
      console.log(file, fileList);
    },

    handlePreview(file) {
      console.log(file);
    },


    onSubmit() {
      axios({
        method:'post',
        url:'/api/History/setHistory.html',
        data:this.form
      }).then((res)=>{
        if(res.data.code != 200) {
          alert(res.data.msg);
        }
        else{
          alert(res.data.msg);
          this.handleClose();
        }
      })
    },
  }
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>

</style>