import { render, staticRenderFns } from "./LangView.vue?vue&type=template&id=379f2f52&scoped=true&"
import script from "./LangView.vue?vue&type=script&lang=js&"
export * from "./LangView.vue?vue&type=script&lang=js&"
import style0 from "./LangView.vue?vue&type=style&index=0&id=379f2f52&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "379f2f52",
  null
  
)

export default component.exports